<template>
  <div class="w-100 d-flex align-items-center"></div>
</template>
<script>
export default {
  emits: ["getAudioBlob", "getMediaRecorderState"],
  name:'AudioRecorder',
  data() {
    return {
      audioPermission: { audio: true },
      isRecording: false,
      isPlaying: false,
      audioChunks: [],
      audioBlob: null,
      mainStream: null,
      base64Data: null,
      mediaRecorderState: null,
    };
  },
  methods: {
    async startRecording() {
      try {
        this.mainStream = await navigator.mediaDevices.getUserMedia(
            this.audioPermission
        );
        this.audioChunks = [];
        this.handleRecording();
      } catch (error) {
        console.log(error);
        if (
            error.name === "NotFoundError" ||
            error.name === "DevicesNotFoundError"
        ) {
          // this.$toast.show({content: `هیچ میکروفونی روی دستگاه شما یافت نشد`,Title:'عملیات ناموفق',Icon:'alert'})
          alert("هیچ میکروفونی روی دستگاه شما یافت نشد")
        } else if (
            error.name === "NotAllowedError" ||
            error.name === "PermissionDeniedError"
        ) {
          // this.$toast.show({content: `دسترسی میکروفون خود را فعال کنید`,Title:'عملیات ناموفق',Icon:'alert'})
          alert("دسترسی میکروفون خود را فعال کنید")
        }
      }
    },
    handleRecording() {
      this.mediaRecorder = new MediaRecorder(this.mainStream);
      this.mediaRecorder.start();
      this.mediaRecorder.addEventListener("dataavailable", (event) => {
        this.audioChunks.push(event.data);
      });
      this.$emit("getMediaRecorderState", this.mediaRecorder.state);
    },
    async stopRecording() {
      await this.mediaRecorder.addEventListener("stop", () => {
        this.audioBlob = new Blob(this.audioChunks, {
          type: this.mediaRecorder.mimeType,
        });

        this.$emit("getAudioBlob", this.audioBlob);
      });
      if (this.mediaRecorder.state !== "inactive") {
        this.mainStream.getTracks()[0].stop();
        this.mediaRecorder.stop();
      }
      this.isPlaying = true;
      this.$emit("getMediaRecorderState", this.mediaRecorder.state);
    },
  },
};
</script>
<style scoped></style>